angular.module("vgresiduos").controller("DestinationCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"$state",
	"$stateParams",
	"httpService",
	"disposalService",
	"dialogService",
	"externalSystemService",
	"deviceDetector",
	"additionalPropertyUtilService",
	"clientService",
	"permissionService",
	"accountService",
	"disposalCostService",
	"stockControlPreferenceService",
	"residueService",
	"asyncTimeoutService",
	"$window",
	"userAccessService",
	"disposalPreferenceService",
	function (
		$rootScope,
		$scope,
		$q,
		$state,
		$stateParams,
		httpService,
		disposalService,
		dialogService,
		externalSystemService,
		deviceDetector,
		additionalPropertyUtilService,
		clientService,
		permissionService,
		accountService,
		disposalCostService,
		stockControlPreferenceService,
		residueService,
		asyncTimeoutService,
		$window,
		userAccessService,
		disposalPreferenceService
	) {
		("use strict");

		const currentStateId = Vgr.util.uuid();
		const viewPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.ViewDisposal);
		const saveUnfinishedPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.CreateEditDeleteDisposalUnfinished
		);
		const saveFinishedPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.EditDeleteDisposalFinished);
		const savePastMonthsDisposalPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.ResidueDisposalCreateEditDeletePastMonths
		);
		const hasAccessToCreateDisposalModel =
			Vgr.enumerations.userAccess.Enabled === userAccessService.getAccess(Vgr.systemPermissions.CrudDisposalModels);
		const decimalPlaces = clientService.getDecimalPlaces();

		$scope.createdFromDisposalModel = $stateParams.createdFromDisposalModel;
		$scope.forceIntegrations = $stateParams.forceIntegrations;
		$scope.alreadyLoadedReviewAfterCreationFromModel = false;

		$scope.disposalUpdatedFileEvent = {};

		$scope.model = {};
		$scope.unlockDisposalGatheringPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.UnlockDisposalGathering
		);

		$scope.labels = $rootScope.labels;
		$scope.isCreating = $state.is("destinationNew");
		$scope.disposalPreferences = null;
		$scope.destinationModel = {
			Residues: [],
			Informations: [],
			Pendencies: [],
			Files: [],
			ExternalSystems: [],
			Cost: {
				DisposalIsIncome: false
			},
			Notifications: [],
			Responsible: null,
			Driver: null,
			Vehicle: null
		};
		$scope.readOnlyModel = {};
		$scope.disposalValidations = {};
		$scope.validations = [];
		$scope.destinationConfig = {
			currentStage: null,
			enabledStagePointer: null,
			currentStepPointer: null,
			enabledStepPointer: null,
			blockEnableNextStep: false
		};
		$scope.validationTypeEnum = Vgr.enumerations.destination.validationType;
		$scope.stepsEnum = Vgr.enumerations.destination.steps;
		$scope.stagesEnum = Vgr.enumerations.destination.stages;

		$scope.destinationFlow = { canLoadStages: false };
		$scope.destinationStages = {
			configSteps: false,
			setDestinatorAlertMessage: false
		};

		$scope.alertMessages = {};

		$scope.loadings = {
			update: false,
			files: false,
			integration: false
		};

		$scope.fileOptions = {
			generalDocuments: {},
			invoice: {},
			manifest: {},
			CDF: {}
		};

		$rootScope.contentNavBar = {
			back: function () {
				$state.go("disposalFollowup");
			},
			breadcrumb: $scope.isCreating ? $scope.labels.NEW_SOLICITATION : " ",
			title: $scope.labels.DISPOSAL,
			valid: true,
			customButtons: [
				{
					label: $scope.labels.FOLLOW_GATHERINGS,
					buttonClasses: "md-primary",
					supressToast: true,
					onClick: function () {
						const deferred = $q.defer();
						$state.go("disposalFollowup");
						deferred.resolve();
						return deferred.promise;
					}
				}
			],
			buttons: [$scope.labels.FOLLOW_GATHERINGS]
		};

		const onSetDisposalLoad = $rootScope.$on(Vgr.constants.evtSetDisposalLoad, function (_event, load) {
			if (load) {
				$scope.loadStage();
			}
		});

		this.$onInit = function () {
			if (!viewPermission) {
				accountService.goHome();
				return;
			}

			Promise.all([loadDisposalPreferences(), loadStockControlPreferences()]).then(function () {
				if ($scope.isCreating) {
					setStateDisposalModelAndOrigin();

					$scope.$apply(function () {
						$scope.destinationFlow.canLoadStages = true;
					});
				} else {
					updateContentNavBarCustomButtons();
					loadDisposal();
				}
			});
		};

		function updateContentNavBarCustomButtons() {
			if (hasAccessToCreateDisposalModel) {
				$rootScope.contentNavBar.customButtons = [
					{
						label: $scope.labels.CREATE_DISPOSAL_MODEL_FROM_DISPOSAL,
						buttonClasses: "primary-color",
						supressToast: true,
						onClick: function () {
							const url = $state.href("destinationCreateModel", {
								disposal: $scope.destinationModel.Id
							});
							$window.open(url, "_blank");
							return $q.resolve();
						}
					},
					...$rootScope.contentNavBar.customButtons
				];

				$rootScope.contentNavBar.buttons = [
					$scope.labels.CREATE_DISPOSAL_MODEL_FROM_DISPOSAL,
					...$rootScope.contentNavBar.buttons
				];
			}
		}

		$scope.onStagesLoaded = function () {
			if ($scope.isCreating) {
				afterStagesLoadedNewDisposal();
			} else {
				afterStagesLoadedEditingDisposal();
			}
		};

		function afterStagesLoadedNewDisposal() {
			listClientExternalSystems();
			$scope.destinationStages.configSteps = true;

			setEditPermission();
			setInitialConfig();
			$scope.initLoadingFinished = true;
		}

		function afterStagesLoadedEditingDisposal() {
			listClientExternalSystems().then(
				function () {
					afterClientExternalSystemsLoadedEditingDisposal();
				},
				function () {
					afterClientExternalSystemsLoadedEditingDisposal();
				}
			);
		}

		function afterClientExternalSystemsLoadedEditingDisposal() {
			$scope.destinationStages.configSteps = true;
			$scope.validateDisposal();

			$scope.initLoadingFinished = true;

			setEditPermission();
			$scope.setInitialStage();
			$scope.configureDisposal();
			calculateBlockedFieldsBasedOnDisposalDate();
			$scope.loadNotifications();
		}

		$scope.loadStage = function () {
			if ($scope.destinationConfig.currentStage && $scope.destinationConfig.currentStage.load) {
				$scope.destinationConfig.currentStage.load();
			}
		};

		$scope.canCalculateCosts = function () {
			return $scope.destinationModel.GatheringDate != null;
		};

		$scope.getEventKey = function (event) {
			return Vgr.util.generateEventKey(event, currentStateId);
		};

		$scope.isTemporaryStorage = function () {
			return $scope.destinationModel.Model == Vgr.enumerations.destination.model.TemporaryStorage;
		};

		$scope.isGatheringStage = function () {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Gathering;
		};

		$scope.isArrivalStage = function () {
			return $scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Arrival;
		};

		$scope.isArrivalOrConfirmationStage = function () {
			return (
				$scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Arrival ||
				$scope.destinationConfig.currentStage.Id == $scope.stagesEnum.Confirmation
			);
		};

		$scope.hasIntegrations = function () {
			return $scope.destinationModel.Manifests &&
				$scope.destinationModel.Manifests.find(
					(manifest) => manifest.Source.Id != Vgr.enumerations.destination.systemSource.Local
				)
				? true
				: false;
		};

		$scope.hasAnyIntegratedManifest = function () {
			if (!$scope.hasIntegrations()) {
				return false;
			}

			const notIntegratedStatus = [
				Vgr.enumerations.destination.systemStatus.Pending,
				Vgr.enumerations.destination.systemStatus.Canceled,
				Vgr.enumerations.destination.systemStatus.CanceledByDeadline,
				Vgr.enumerations.destination.systemStatus.Refused
			];

			return $scope.destinationModel.Manifests.some(
				(manifest) =>
					manifest.Source.Id != Vgr.enumerations.destination.systemSource.Local &&
					!notIntegratedStatus.includes(manifest.Status)
			);
		};

		$scope.isFinalized = function () {
			return (
				$scope.destinationModel.Status == Vgr.enumerations.destination.status.Finalized ||
				$scope.destinationModel.Status == Vgr.enumerations.destination.status.Pending
			);
		};

		$scope.isQuantityMandatory = function () {
			return (
				isReceivingDisposalForDestinator() ||
				$scope.destinationModel.Status == Vgr.enumerations.destination.status.Received ||
				$scope.destinationModel.Status == Vgr.enumerations.destination.status.Pending ||
				$scope.destinationModel.Status == Vgr.enumerations.destination.status.Finalized
			);
		};

		$scope.isGathered = function () {
			return (
				$scope.destinationModel.Status == Vgr.enumerations.destination.status.Gathered ||
				$scope.destinationModel.Status == Vgr.enumerations.destination.status.Received ||
				$scope.destinationModel.Status == Vgr.enumerations.destination.status.Pending ||
				$scope.destinationModel.Status == Vgr.enumerations.destination.status.Finalized
			);
		};

		$scope.isCurrentStageCompleted = function () {
			return $scope.destinationConfig.currentStage.IsCompleted;
		};

		$scope.isCurrentStageReview = function () {
			return (
				($scope.destinationConfig.currentStage &&
					$scope.destinationConfig.enabledStagePointer &&
					$scope.destinationConfig.currentStage.Id < $scope.destinationConfig.enabledStagePointer) ||
				$scope.isFinalized() ||
				!$scope.editPermission
			);
		};

		$scope.showCurrentStageCompletedStep = function () {
			return $scope.loadReviewStepAfterCreationFromModel() && !$scope.isCurrentStageReview();
		};

		$scope.loadReviewStepAfterCreationFromModel = function () {
			return (
				$scope.createdFromDisposalModel &&
				JSON.parse($scope.createdFromDisposalModel) &&
				!$scope.alreadyLoadedReviewAfterCreationFromModel
			);
		};

		$scope.disposalForceIntegrations = function () {
			return $scope.forceIntegrations && JSON.parse($scope.forceIntegrations);
		};

		$scope.isOriginHistory = function () {
			return (
				$scope.destinationModel &&
				($scope.destinationModel.OriginChannel == Vgr.enumerations.channel.disposal.disposalScreenHistory ||
					$scope.destinationModel.OriginChannel == Vgr.enumerations.channel.disposal.dataLoad ||
					$scope.destinationModel.OriginChannel == Vgr.enumerations.channel.disposal.mtrImport)
			);
		};

		$scope.isOriginHistoryThatCantUseMtr = function () {
			return (
				$scope.destinationModel &&
				($scope.destinationModel.OriginChannel == Vgr.enumerations.channel.disposal.disposalScreenHistory ||
					$scope.destinationModel.OriginChannel == Vgr.enumerations.channel.disposal.dataLoad)
			);
		};

		$scope.updateSlip = function (stepId, valueToShow) {
			const step = $scope.destinationConfig.currentStage.Steps[stepId];
			if (step) {
				step.ValueToShow = valueToShow;
			}
		};

		$scope.loadNotifications = function () {
			const deferred = $q.defer();

			$scope.loadings.notifications = true;
			httpService.getDTOFromServiceV2(disposalService.listDisposalNotifications, $scope.destinationModel.Id).then(
				function (data) {
					$scope.destinationModel.Notifications = data.Notifications;
					$scope.loadings.notifications = false;
					deferred.resolve();
				},
				function () {
					$scope.loadings.notifications = false;
					deferred.reject();
				}
			);

			return deferred.promise;
		};

		$scope.createValidateDto = function (transporter, ignoreDestinatorValidation) {
			if (!$scope.destinationModel.Residues) {
				return {};
			}
			return {
				Model: $scope.destinationModel.Model,
				ResponsibleId: $scope.destinationModel.Responsible ? $scope.destinationModel.Responsible.Id : null,
				RequestDate: Vgr.date.fromDateToUTCString($scope.destinationModel.RequestDate),
				GatheringDate: Vgr.date.fromDateToUTCString($scope.destinationModel.GatheringDate),
				EstimatedGatheringDate: Vgr.date.fromDateToUTCString($scope.destinationModel.EstimatedGatheringDate),
				ReceivedDate: Vgr.date.fromDateToUTCString($scope.destinationModel.ReceivingDate),
				DisposalDate: Vgr.date.fromDateToUTCString($scope.destinationModel.DisposalDate),
				DestinatorId: $scope.destinationModel.Destinator ? $scope.destinationModel.Destinator.Id : null,
				IgnoreDestinatorValidation: ignoreDestinatorValidation,
				TemporaryStoragerId: $scope.destinationModel.TemporaryStorager
					? $scope.destinationModel.TemporaryStorager.Id
					: null,
				TemporaryStoragerReceivedDate: Vgr.date.fromDateToUTCString(
					$scope.destinationModel.TemporaryStoragerReceivingDate
				),
				TemporaryStoragerDispatchDate: Vgr.date.fromDateToUTCString(
					$scope.destinationModel.TemporaryStoragerDispatchDate
				),
				TransporterId: transporter ? transporter.Id : null,
				DriverId: $scope.destinationModel.Driver ? $scope.destinationModel.Driver.id : null,
				VehicleId: $scope.destinationModel.Vehicle ? $scope.destinationModel.Vehicle.id : null,
				ExternalIntegrations: createValidateExternalIntegrationsDto(),
				Residues: $scope.getResiduesDto(),
				DisposalId: $scope.destinationModel.Id,
				Documents: $scope.getDocumentsDto()
			};
		};

		$scope.getDocumentsDto = function () {
			const documents = [];
			for (const disposalResidue of $scope.destinationModel.Residues.filter((r) => !!r.CadriDocumentId)) {
				if (documents.find((d) => d.ResidueCode == disposalResidue.Code)) {
					continue;
				}

				documents.push({
					ResidueCode: disposalResidue.Code,
					DocumentId: disposalResidue.CadriDocumentId
				});
			}

			return documents;
		};

		$scope.getResiduesDto = function () {
			const residuesDto = [];

			for (const disposalResidue of $scope.destinationModel.Residues) {
				let alreadyBuiltResidue = residuesDto.find((residue) => residue.ResidueId == disposalResidue.ResidueId);

				if (!alreadyBuiltResidue) {
					alreadyBuiltResidue = {
						Id: disposalResidue.Id,
						ResidueId: disposalResidue.ResidueId,
						ResidueCode: disposalResidue.Code,
						DisposalTypeId: disposalResidue.DisposalTypeId,
						RecipientId: disposalResidue.RecipientId,
						RecipientGuidId: disposalResidue.RecipientGuidId,
						RecipientQuantity: disposalResidue.RecipientQuantity,
						AreaCode: disposalResidue.AreaCode ? disposalResidue.AreaCode : null,
						StockAdjustmentType: disposalResidue.StockAdjustmentType,
						GenerationRecipients: getGenerationRecipients(disposalResidue),
						Quantities: []
					};

					residuesDto.push(alreadyBuiltResidue);
				}
				if (disposalResidue.MeasureUnitId) {
					for (const quantity of disposalResidue.QuantitiesByArea) {
						if (
							quantity.Quantity > 0 ||
							quantity.TareQuantity > 0 ||
							quantity.ScaleAuxiliar1 > 0 ||
							quantity.ScaleAuxiliar2 > 0
						) {
							let areaCode;
							if (quantity.SelectedArea) {
								areaCode = quantity.SelectedArea.code;
							} else if (quantity.Area) {
								areaCode = quantity.Area.Code;
							} else {
								areaCode = quantity.AreaCode;
							}
							alreadyBuiltResidue.Quantities.push({
								Quantity: quantity.Quantity,
								TareQuantity: quantity.TareQuantity,
								GrossQuantity: quantity.GrossQuantity,
								ScaleQuantity: quantity.ScaleQuantity,
								ScaleAuxiliar1: quantity.ScaleAuxiliar1,
								ScaleAuxiliar2: quantity.ScaleAuxiliar2,
								ScalingType: quantity.ScalingType ? quantity.ScalingType : null,
								MeasureUnitId: quantity.MeasureUnit.Id,
								AreaCode: areaCode
							});
						}
					}
				}
			}

			return residuesDto;
		};

		function getGenerationRecipients(disposalResidue) {
			return disposalResidue.InternalGatheringRecipientQuantities?.filter((igr) => igr.RecipientQuantity > 0).map(
				(i) => {
					return {
						RecipientId: i.RecipientId ?? disposalResidue.RecipientGuidId,
						Quantity: i.RecipientQuantity
					};
				}
			);
		}

		$scope.updateValidationList = function (validations) {
			$scope.validations = validations;
		};

		$scope.validateRequestDate = function () {
			const deferred = $q.defer();

			if ($scope.canValidateDisposal()) {
				$scope.validateDisposal().then(
					function () {
						deferred.resolve();
					},
					function () {
						deferred.resolve();
					}
				);

				return deferred.promise;
			}

			if (!$scope.destinationModel.RequestDate || savePastMonthsDisposalPermission) {
				deferred.resolve();
				return deferred.promise;
			}

			if ($scope.destinationModel.RequestDate != null && !isDateValid($scope.destinationModel.RequestDate)) {
				const validation = { Type: Vgr.enumerations.validation.RequestDateBeforeMinAllowedDate, IsToBlock: true };
				$scope.updateValidationList([validation]);
				deferred.reject(validation);
			} else {
				deferred.resolve();
			}

			return deferred.promise;
		};

		$scope.openDisposalNotificationsPopup = function (notification) {
			$scope.notificationToPopup = notification;
			const dialog = dialogService
				.showDialogFromTemplateWithScope(
					null,
					"views/destination/popups/disposalNotificationPopup/disposalNotificationPopup_template.html",
					"DisposalNotificationPopupCtrl",
					$scope,
					"#disposal-popup",
					true,
					function () {
						// do nothing
					}
				)
				.then(
					function () {
						// do nothing
					},
					function () {
						// do nothing
					}
				);
			$scope.dialog = dialog;
		};

		$scope.listClientExternalSystems = function () {
			return listClientExternalSystems();
		};

		$scope.canValidateDisposal = function () {
			if ($scope.isTemporaryStorage()) {
				return (
					$scope.destinationModel.TemporaryStorager &&
					$scope.destinationModel.TemporaryStorager.Id &&
					$scope.destinationModel.Residues.length > 0
				);
			}
			return (
				$scope.destinationModel.Destinator &&
				$scope.destinationModel.Destinator.Id &&
				$scope.destinationModel.Residues.length > 0
			);
		};

		$scope.buildDisposalModel = function (disposal) {
			const deferredDisposal = $q.defer();

			$scope.destinationModel = {
				Id: disposal.Id,
				Code: disposal.Code,
				Model: disposal.Model,
				OriginChannel: disposal.OriginChannel,
				Client: {
					Id: disposal.Client.Id,
					Code: disposal.Client.Code,
					Latitude: disposal.Latitude,
					Longitude: disposal.Longitude
				},
				Destinator: buildSupplierModel(disposal.Destinator),
				Transporter: buildSupplierModel(disposal.Transporter),
				TemporaryStorager: buildSupplierModel(disposal.TemporaryStorager),
				Status: disposal.Status,
				RequestDate: Vgr.util.getDateFromString(disposal.RequestDate),
				GatheringDate: Vgr.util.getDateFromString(disposal.GatheringDate),
				EstimatedGatheringDate: Vgr.util.getDateFromString(disposal.EstimatedGatheringDate),
				ReceivingDate: Vgr.util.getDateFromString(disposal.ReceivingDate),
				DisposalDate: Vgr.util.getDateFromString(disposal.DisposalDate),
				TemporaryStoragerReceivingDate: Vgr.util.getDateFromString(disposal.TemporaryStoragerReceivingDate),
				TemporaryStoragerDispatchDate: Vgr.util.getDateFromString(disposal.TemporaryStoragerDispatchDate),
				Driver: disposal.Driver,
				Vehicle: disposal.Vehicle,
				Responsible: {
					Id: disposal.Responsible.IdentityId,
					Name: disposal.Responsible.Name
				},
				InvoiceCode: disposal.InvoiceCode,
				InvoiceStatus: disposal.InvoiceStatus,
				DeleteStatus: disposal.DeleteStatus,
				DestinatorResponsible: disposal.DestinatorResponsible
					? {
							id: disposal.DestinatorResponsible.Id,
							name: disposal.DestinatorResponsible.Name
					  }
					: null,
				NeedsDriverMopp: disposal.NeedsDriverMopp,
				NeedsVehicleInmetro: disposal.NeedsVehicleInmetro,
				LockedGathering: disposal.LockedGathering,
				IsDisposingAbove45Ton: disposal.IsDisposingAbove45Ton,
				LockReason: disposal.LockReason,
				Observations: disposal.Observations,
				Residues: buildDisposalResidues(disposal.Residues),
				Informations: buildDisposalInformations(disposal.Informations),
				Pendencies: $scope.buildDisposalPendencies(disposal.Pendencies),
				Notifications: [],
				Manifests: disposal.Manifests,
				InvoiceFiles: buildDisposalInvoiceFiles(disposal.Files),
				GeneralFiles: buildDisposalGeneralFiles(disposal.Files),
				CDFFiles: buildDisposalCDFFiles(disposal.Files),
				ManifestFiles: buildDisposalManifestFiles(disposal.Files),
				ComplementaryMtrFiles: buildDisposalComplementaryMtrFiles(disposal.Files),
				DisposalCost: disposalCostService.formatDisposalCost(disposal.DisposalCostContract)
			};

			calculateShowDisposalCostInformation();

			buildPhysicalFiles(disposal.PhysicalFiles);

			deferredDisposal.resolve();
			return deferredDisposal.promise;
		};

		function calculateShowDisposalCostInformation() {
			if ($scope.destinationModel.Status == Vgr.enumerations.destination.status.Requested) {
				return;
			}

			if ($scope.destinationModel.DisposalCost?.totalCost != null) {
				$scope.showDisposalCostInformation = true;
				return;
			}

			calculateDisposalCosts();
		}

		const onRecalculateDisposalCost = $rootScope.$on(Vgr.constants.evtRecalculateDisposalCost, function () {
			calculateDisposalCosts();
		});

		function calculateDisposalCosts() {
			disposalCostService.calculateDisposalCosts(getCalculateDisposalCostRequest()).then(
				function (cost) {
					$scope.destinationModel.DisposalCost = cost;
					$scope.showDisposalCostInformation = true;
					$rootScope.$broadcast(Vgr.constants.evtDisposalCostChanged, $scope.destinationModel.DisposalCost);
					updateDisposalCosts();
				},
				function (error) {
					Cmp.util.showError(error);
					$scope.showDisposalCostInformation = true;
				}
			);
		}

		function updateDisposalCosts() {
			const destinationDto = disposalCostService.getUpdateCostsDto(
				disposalCostService.fillDisposalCostResidueByGrid(
					$scope.destinationModel.DisposalCost,
					$scope.destinationModel.Residues
				),
				$scope.destinationModel
			);
			httpService.postDTOToServiceV2(disposalCostService.updateDisposalCosts, destinationDto).then(
				function (response) {
					$rootScope.$broadcast(Vgr.constants.evtDisposalCostChanged, response);
				},
				function () {}
			);
		}

		function getCalculateDisposalCostRequest() {
			const disposalModel = $scope.destinationModel;
			return {
				DestinatorId: disposalModel.Destinator?.SupplierId,
				TransporterId: disposalModel.Transporter?.SupplierId,
				GatheringDate: disposalModel.GatheringDate,
				DisposalResidues: getCalculateDisposalCostResidueRequest()
			};
		}

		function getCalculateDisposalCostResidueRequest() {
			return $scope.destinationModel.Residues.map((residue) => {
				return {
					ResidueId: residue.ResidueGuidId,
					RecipientId: residue.RecipientGuidId,
					MeasureUnitId: residue.MeasureUnitId,
					ResidueQuantity: residue.Quantity
				};
			});
		}

		$scope.disableNewIntegrations = function () {
			return $scope.destinationModel.InvoiceStatus == Vgr.enumerations.destination.invoiceStatus.Canceled;
		};

		$scope.buildDisposalModelAfterCreate = function (disposal) {
			$scope.destinationModel.Id = disposal.Id;
			$scope.destinationModel.LockedGathering = disposal.LockedGathering;
			$scope.destinationModel.IsDisposingAbove45Ton = disposal.IsDisposingAbove45Ton;
			$scope.destinationModel.Status = disposal.Status;
			$scope.destinationModel.Client = disposal.Client;
			$scope.destinationModel.Manifests = disposal.Manifests;

			$scope.destinationModel.InvoiceFiles = buildDisposalInvoiceFiles(disposal.Files);
			$scope.destinationModel.GeneralFiles = buildDisposalGeneralFiles(disposal.Files);
			$scope.destinationModel.CDFFiles = buildDisposalCDFFiles(disposal.Files);
			$scope.destinationModel.ManifestFiles = buildDisposalManifestFiles(disposal.Files);
			$scope.destinationModel.ComplementaryMtrFiles = buildDisposalComplementaryMtrFiles(disposal.Files);
			$scope.destinationModel.Pendencies = $scope.buildDisposalPendencies(disposal.Pendencies);
		};

		$scope.updateReadOnlyModel = function (disposal) {
			$scope.readOnlyModel = disposal;
		};

		$scope.updateModel = function (disposal) {
			const deferred = $q.defer();
			$scope.initLoadingFinished = false;
			$scope.disposalValidations = {
				Destinator: $scope.disposalValidations.Destinator,
				Transporter: $scope.disposalValidations.Transporter,
				TemporaryStorager: $scope.disposalValidations.TemporaryStorager
			};
			$scope.configureDisposal();
			$scope.loadNotifications();
			$scope.buildDisposalModel(disposal).then(
				function () {
					$scope.validateDisposal();
					if ($scope.isCurrentStageReview()) {
						$scope.stagesConfig.currentStepPointer = null;
					} else {
						$scope.stagesConfig.currentStepPointer = $scope.stepsEnum.A;
					}
					$scope.initLoadingFinished = true;
					deferred.resolve();
				},
				function () {
					$scope.initLoadingFinished = true;
					deferred.reject();
				}
			);
			$scope.destinationModel.Pendencies = $scope.buildDisposalPendencies(disposal.Pendencies);
			return deferred.promise;
		};

		$scope.isMobile = function () {
			return deviceDetector.isMobile();
		};

		function loadDisposalPreferences() {
			const deferred = $q.defer();

			if ($scope.disposalPreferences) {
				deferred.resolve();
				return deferred.promise;
			}

			disposalPreferenceService.getDisposalPreferences().then(
				function (disposalPreferences) {
					$scope.disposalPreferences = disposalPreferences;
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function loadStockControlPreferences() {
			const deferred = $q.defer();

			if ($scope.stockControlPreferences) {
				deferred.resolve();
				return deferred.promise;
			}

			stockControlPreferenceService.getStockControlPreferences().then(
				function (preferences) {
					$scope.stockControlPreferences = preferences;
					deferred.resolve();
				},
				function () {
					$scope.stockControlPreferences = {};
					deferred.resolve();
				}
			);
			return deferred.promise;
		}

		$scope.getMaxDaysEstimatedGathering = function () {
			const defaultMaxDaysEstimatedGathering = 10;

			return $scope.disposalPreferences.maxDaysEstimatedGathering ?? defaultMaxDaysEstimatedGathering;
		};

		function loadDisposal() {
			httpService.getDTOFromServiceV2(disposalService.getDisposal, $stateParams.id).then(
				function (data) {
					if (data && data.Disposal) {
						$scope.readOnlyModel = data.Disposal;
						$rootScope.contentNavBar.breadcrumb = data.Disposal.Code;
						$scope.buildDisposalModel(data.Disposal).then(
							function () {
								$scope.destinationFlow.canLoadStages = true;
							},
							function () {
								$state.go("disposalFollowup");
							}
						);
					}
				},
				function () {
					$state.go("disposalFollowup");
				}
			);
		}

		function setStateDisposalModelAndOrigin() {
			const stateModel = $state.params.modelo;
			const stateOrigin = $state.params.origem;

			if (stateModel == $scope.labels.COMPLETE_MODEL_URL) {
				$scope.destinationModel.Model = Vgr.enumerations.destination.model.Complete;
			} else if (stateModel == $scope.labels.TEMPORARY_STORAGE_MODEL_URL) {
				$scope.destinationModel.Model = Vgr.enumerations.destination.model.TemporaryStorage;
			} else {
				$scope.destinationModel.Model = Vgr.enumerations.destination.model.Complete;
			}

			if (stateOrigin == $scope.labels.ORIGIN_HISTORY_URL) {
				$scope.destinationModel.OriginChannel = Vgr.enumerations.channel.disposal.disposalScreenHistory;
			} else {
				$scope.destinationModel.OriginChannel = Vgr.enumerations.channel.disposal.disposalScreenWithoutModel;
			}
		}

		function setInitialConfig() {
			$scope.destinationConfig.currentStage = $scope.stagesConfig[$scope.stagesEnum.Solicitation];
			$scope.destinationConfig.enabledStagePointer = $scope.stagesConfig[$scope.stagesEnum.Solicitation].Id;
		}

		function setEditPermission() {
			if ($scope.isCreating) {
				$scope.editPermission = saveUnfinishedPermission;
			} else {
				if ($scope.readOnlyModel.Status == Vgr.enumerations.destination.status.Finalized) {
					$scope.editPermission = saveFinishedPermission;
				} else {
					$scope.editPermission = saveUnfinishedPermission;
				}
			}
		}

		$scope.validateDisposal = function () {
			const deferred = $q.defer();

			if (!$scope.canValidateDisposal()) {
				deferred.resolve();
				return deferred.promise;
			}

			const dto = $scope.createValidateDto($scope.destinationModel.Transporter);

			httpService.getDTOFromServiceV2(disposalService.validateDisposal, dto).then(
				function (response) {
					$scope.updateValidationList(response.Validations);
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		};

		$scope.toDestinatorArrivalStage = function () {
			httpService.getDTOFromServiceV2(disposalService.getDisposal, $scope.destinationModel.Id).then(
				function (data) {
					$scope.updateReadOnlyModel(data.Disposal);
					$scope.updateModel(data.Disposal);
					$scope.destinationConfig.currentStage = $scope.stagesConfig[$scope.stagesEnum.Arrival];
					$scope.loadings.update = false;
				},
				function () {
					$scope.loadings.update = false;
				}
			);
		};

		$scope.getCustomValidationMessage = function (validation) {
			let validationMessage = $scope.labels.DISPOSAL_VALIDATIONS_DESCRIPTION_ENUM[validation.Type];
			if (validationMessage) {
				if (validation.Param1) {
					validationMessage = validationMessage.replace("[PARAM1]", validation.Param1.toUpperCase());
				}
			} else {
				validationMessage = validation.Message;
			}

			return validationMessage;
		};

		$scope.setValidationMessages = function (validations, validationTypes, atuationType, target) {
			const validationErrors = validations.filter(
				(v) =>
					validationTypes.includes(v.Type) ||
					v.Metadata?.some(
						(d) =>
							d.Key == Vgr.enumerations.destination.validations.suppliers.key &&
							d.Value == Vgr.enumerations.destination.validations.suppliers.atuation[atuationType]
					)
			);

			const validationMessages = [];
			for (const validation of validationErrors) {
				validationMessages.push($scope.getCustomValidationMessage(validation));
			}

			$scope.disposalValidations[target].Messages = validationMessages;
		};

		$scope.getInformationMessageTooltip = function (messages) {
			return messages.map((error) => "• " + error).join("<br/>");
		};

		$scope.goToSupplierPage = function (supplier) {
			const url = $state.href("supplierEdit", {
				id: supplier.Id
			});

			$window.open(url, "_blank");
		};

		function createValidateExternalIntegrationsDto() {
			const integrationsDto = [];

			for (const i in $scope.destinationModel.ExternalSystems) {
				if ($scope.destinationModel.ExternalSystems[i].Selected) {
					integrationsDto.push($scope.destinationModel.ExternalSystems[i].SourceId);
				}
			}

			return integrationsDto;
		}

		function isReceivingDisposalForDestinator() {
			if ($scope.isTemporaryStorage()) {
				return (
					$scope.destinationModel.Status == Vgr.enumerations.destination.status.TemporaryStorageReceived &&
					!$scope.isCurrentStageReview()
				);
			}
			return (
				$scope.destinationModel.Status == Vgr.enumerations.destination.status.Gathered && !$scope.isCurrentStageReview()
			);
		}

		function listClientExternalSystems() {
			const deferred = $q.defer();

			httpService
				.getDTOFromServiceV2(
					externalSystemService.listClientExternalSystems,
					{ UnitId: accountService.getCurrentClient().id },
					"ExternalSystems"
				)
				.then(
					function (list) {
						if (list.length && !$scope.isOriginHistory() && !$scope.hasIntegrations()) {
							const stageGathering = $scope.stagesEnum.Gathering;
							const stepIntegrations = $scope.stepsEnum.D;
							$scope.stagesConfig[stageGathering].Steps[stepIntegrations].IsDisabled = false;
						}
						$scope.clientExternalSystems = list;
						deferred.resolve(list);
					},
					function () {
						deferred.reject();
					}
				);
			return deferred.promise;
		}

		function buildSupplierModel(supplier) {
			if (supplier == null) {
				return null;
			}

			const cityStateDescription = supplier.City
				? supplier.City.Description + " - " + supplier.City.State.Abbreviation
				: "";

			return {
				Id: supplier.Id,
				Code: supplier.Code,
				Name: supplier.Name,
				CNPJ: supplier.CNPJ,
				Active: supplier.Active,
				City: supplier.City,
				Phone: supplier.Phone,
				Cep: supplier.Cep,
				Latitude: supplier.Latitude,
				Longitude: supplier.Longitude,
				CityStateDescription: cityStateDescription,
				CadriEnabled: supplier.CadriEnabled,
				SupplierId: supplier.SupplierId
			};
		}

		function buildDisposalInvoiceFiles(files) {
			return modelFiles(
				files.filter(function (file) {
					return file.Type == Vgr.enumerations.destination.file.Invoice;
				})
			);
		}

		function buildDisposalGeneralFiles(files) {
			return modelFiles(
				files.filter(function (file) {
					return file.Type == Vgr.enumerations.destination.file.GeneralDocument;
				})
			);
		}

		function buildDisposalCDFFiles(files) {
			return modelFiles(
				files.filter(function (file) {
					return file.Type == Vgr.enumerations.destination.file.CDF;
				})
			);
		}

		function buildDisposalManifestFiles(files) {
			return modelFiles(
				files.filter(function (file) {
					return file.Type == Vgr.enumerations.destination.file.Manifest;
				})
			);
		}

		function buildDisposalComplementaryMtrFiles(files) {
			return modelFiles(
				files.filter(function (file) {
					return file.Type == Vgr.enumerations.destination.file.ComplementaryMtr;
				})
			);
		}

		function buildPhysicalFiles(physicalfiles) {
			if (physicalfiles) {
				const manifestFile = physicalfiles.find((pf) => pf == Vgr.enumerations.destination.file.Manifest);
				const CDFFile = physicalfiles.find((pf) => pf == Vgr.enumerations.destination.file.CDF);
				const invoiceFile = physicalfiles.find((pf) => pf == Vgr.enumerations.destination.file.Invoice);

				if (manifestFile) {
					$scope.destinationModel.PhysicalFilesManifest = true;
				}
				if (CDFFile) {
					$scope.destinationModel.PhysicalFilesCDF = true;
				}
				if (invoiceFile) {
					$scope.destinationModel.PhysicalFilesInvoice = true;
				}
			}
		}

		function modelFiles(files) {
			const newFiles = [];
			for (const index in files) {
				newFiles.push({
					Id: files[index].Id,
					Path: files[index].Path,
					Name: files[index].Name,
					Type: files[index].Type
				});
			}
			return newFiles;
		}

		function buildDisposalResidues(residues) {
			const disposalResidues = [];

			for (const residue of residues) {
				const gridResidues = getResidueQuantities(residue);
				for (const gridResidue of gridResidues) {
					disposalResidues.push(gridResidue);
				}
			}

			return disposalResidues;
		}

		function getResidueQuantities(residue) {
			const gridResidues = [];

			const baseGridResidue = {
				Id: residue.Id,
				Code: residue.Code,
				ResidueId: residue.ResidueId,
				ResidueName: residue.ResidueName,
				DisposalTypeId: residue.DisposalTypeId,
				DisposalTypeDescription: residue.DisposalTypeDescription,
				Quantity: null,
				QuantityString: null,
				MeasureUnitId: null,
				MeasureUnitAbbreviation: null,
				RecipientId: residue.RecipientId,
				RecipientGuidId: residue.RecipientGuidId,
				RecipientName: residue.RecipientDescription,
				RecipientFormattedToGrid: getRecipientFormattedToGrid(residue),
				RecipientQuantity: residue.RecipientQuantity,
				IsDangerous: residue.Dangerous,
				AreaCode: null,
				ControlsStock: residue.ControlsStock,
				HasManualUserAssociation: residue.HasManualUserAssociation,
				Quantities: residue.ControlsStock
					? residue.Quantities
					: residueService.getResidueQuantitiesByAreaNotControlStock(residue.Quantities),
				InternalGatheringRecipientQuantities: residue.ControlsStock ? residue.InternalGatheringRecipientQuantities : [],
				RecipientPercentualEfficiency: residue.RecipientPercentualEfficiency,
				Storages: residue.Storages,
				CadriDocumentId: residue.CadriDocumentId,
				ResidueGuidId: residue.ResidueGuidId
			};

			if (residue.Quantities.length == 0) {
				gridResidues.push({ ...baseGridResidue, GridKey: Vgr.util.uuid() });
			} else {
				const duplicatedResidues = $scope.getQuantitiesToGrid(baseGridResidue, residue.Quantities);
				if (duplicatedResidues.length > 0) {
					gridResidues.push(...duplicatedResidues);
				}
			}
			return gridResidues;
		}

		$scope.getQuantitiesToGrid = function (baseGridResidue, quantities) {
			const result = [];
			const convertedQuantities = quantities.reduce((grouped, quantity) => {
				const measureUnitId = quantity.MeasureUnit.Id;
				grouped[measureUnitId] = grouped[measureUnitId] || [];
				grouped[measureUnitId].push(angular.copy(quantity));
				return grouped;
			}, []);

			for (const prop in convertedQuantities) {
				const convertedQuantity = convertedQuantities[prop];
				const totalQuantity = Vgr.util.sumPropArray(convertedQuantity, "Quantity");
				const tareQuantity = Vgr.util.sumPropArray(convertedQuantity, "TareQuantity");
				const scaleAuxiliar1 = Vgr.util.sumPropArray(convertedQuantity, "ScaleAuxiliar1");
				const scaleAuxiliar2 = Vgr.util.sumPropArray(convertedQuantity, "ScaleAuxiliar2");
				const duplicatedResidue = {
					...angular.copy(baseGridResidue),
					GridKey: Vgr.util.uuid(),
					Quantity: totalQuantity,
					TareQuantity: tareQuantity,
					GrossQuantity: Vgr.util.sumPropArray(convertedQuantity, "GrossQuantity"),
					ScaleQuantity: convertedQuantity.some((item) => item.ScaleQuantity),
					ScaleAuxiliar1: scaleAuxiliar1,
					ScaleAuxiliar2: scaleAuxiliar2,
					ScalingType: convertedQuantity[0].ScalingType,
					AdjustedQuantity: convertedQuantity[0].AdjustedQuantity,
					IsEdited: convertedQuantity[0].IsEdited,
					QuantityString: getQuantityString(totalQuantity),
					MeasureUnitId: convertedQuantity[0].MeasureUnit.Id,
					MeasureUnitAbbreviation: convertedQuantity[0].MeasureUnit.Abbreviation,
					QuantitiesByArea: convertedQuantity
				};
				result.push(duplicatedResidue);
			}
			return result;
		};

		function getQuantityString(quantity) {
			if (quantity) {
				return Vgr.util.formatUnitDecimalPlaces(quantity, decimalPlaces);
			}
			return $scope.labels.PENDING_WEIGHT;
		}

		function getRecipientFormattedToGrid(residue) {
			let recipientFormattedToGrid = "";
			if (residue.RecipientQuantity) {
				recipientFormattedToGrid = Vgr.util.formatUnitDecimalPlaces(residue.RecipientQuantity, 1) + " - ";
			}
			if (residue.RecipientDescription) {
				recipientFormattedToGrid = recipientFormattedToGrid + residue.RecipientDescription;
			}

			return recipientFormattedToGrid;
		}

		function buildDisposalInformations(informations) {
			return additionalPropertyUtilService.buildInformationsToGridFromApiV2EntityInformation(informations);
		}

		$scope.buildDisposalPendencies = function (pendencies) {
			const disposalPendencies = [];

			const pendencyTypes = disposalService.listDisposalPendencyTypes();

			for (const i in pendencyTypes) {
				const foundPendency = pendencies && pendencies.find((item) => item.Type == pendencyTypes[i].Id);

				disposalPendencies.push({
					Type: pendencyTypes[i].Id,
					Description: pendencyTypes[i].Description,
					IsChecked: foundPendency ? true : false
				});
			}

			return disposalPendencies;
		};

		function isDateValid(date) {
			if (typeof date == "string") {
				date = Vgr.util.getDateFromString(date);
			}

			const limitDay = $scope.disposalPreferences.currentMonthLimitDay;

			if (!limitDay) {
				return true;
			}

			const today = new Date();

			const twoMonthsAgo = new Date(new Date().setMonth(new Date().getMonth() - 2));

			//caso a data for do mes atual ou posterior
			if (new Date(date.getYear(), date.getMonth(), 1) >= new Date(today.getYear(), today.getMonth(), 1)) {
				return true;
			}
			//caso a data for de dois meses atras ou anterior
			else if (
				new Date(date.getYear(), date.getMonth(), 1) <= new Date(twoMonthsAgo.getYear(), twoMonthsAgo.getMonth(), 1)
			) {
				return false;
			}
			//caso a data for do mes passado, aplica a regra de fato
			else {
				return today.getDate() <= limitDay;
			}
		}

		function calculateBlockedFieldsBasedOnDisposalDate() {
			if (savePastMonthsDisposalPermission || !$scope.readOnlyModel) {
				return;
			}

			if ($scope.readOnlyModel.GatheringDate != null) {
				$scope.isToBlockFieldsBasedOnDisposalDate = !isDateValid($scope.readOnlyModel.GatheringDate);

				setInvalidGatheringDateAlertMessage();
			}
		}

		function setInvalidGatheringDateAlertMessage() {
			$scope.alertMessages[Vgr.enumerations.destination.alertMessages.invalidGatheringDate] = {
				condition: function () {
					return $scope.isToBlockFieldsBasedOnDisposalDate;
				},
				title: $scope.labels.DISPOSAL_FIELDS_BLOCKED_DUE_LIMIT_DAY,
				status: "error"
			};
		}

		function updateDisposalFiles() {
			httpService.getDTOFromServiceV2(disposalService.getDisposal, $scope.destinationModel.Id).then(function (data) {
				$scope.destinationModel.ManifestFiles = buildDisposalManifestFiles(data.Disposal.Files);
				$scope.destinationModel.CDFFiles = buildDisposalCDFFiles(data.Disposal.Files);

				$rootScope.$broadcast(Vgr.constants.evtDisposalFilesUpdated);
			});
		}

		//init disposal files socket methods ---------------------------------------------------------------------------------------
		$scope.listenForDisposalFilesEvents = function (roomName, key, timeout) {
			let fileEvent = getFileEventObject(key);

			fileEvent = subscribeTopic(fileEvent, roomName);
			asyncTimeoutService.startAsyncCallTimer(fileEvent, onTimeoutNoResponseFromServer, timeout, 1);
		};

		function getFileEventObject(key) {
			unsubscribeTopic($scope.disposalUpdatedFileEvent[key]);

			$scope.disposalUpdatedFileEvent[key] = {};
			return $scope.disposalUpdatedFileEvent[key];
		}

		function subscribeTopic(fileEvent, roomName) {
			fileEvent.observable$ = window.Amplify.API.graphql(
				window.Amplify.graphqlOperation(Vgr.constants.graphql.subscriptions, {
					name: roomName
				})
			).subscribe({
				next: () => callBackTopicSucces(fileEvent),
				error: (error) => {
					console.log(error);
				}
			});

			return fileEvent;
		}

		function onTimeoutNoResponseFromServer(fileEvent) {
			unsubscribeTopic(fileEvent);
		}

		function callBackTopicSucces(fileEvent) {
			unsubscribeTopic(fileEvent);

			updateDisposalFiles();
		}

		function unsubscribeTopic(fileEvent) {
			if (fileEvent && fileEvent.observable$) {
				asyncTimeoutService.cancelAsyncTimer(fileEvent);
				fileEvent.observable$.unsubscribe();
			}
		}

		$scope.$on("$destroy", function () {
			// disable the listener
			onSetDisposalLoad();
			onRecalculateDisposalCost();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
