angular.module("componentes").component("vgrLogo", {
	controller: function ($scope) {
		this.$onInit = function () {
			if (Vgr.util.isIusNatura()) {
				if ($scope.$ctrl.white) {
					$scope.logo = Vgr.constants.s3BaseUrl + "public/white-label/logo_ius_branca.png";
				} else {
					$scope.logo = Vgr.constants.s3BaseUrl + "public/white-label/logo_ius_login.png";
				}
				return;
			}
			let imageUrl = Vgr.constants.s3BaseUrl;
			if ($scope.$ctrl.white) {
				imageUrl += Vgr.constants.content.vertownWhiteLogoLink;
			} else if ($scope.$ctrl.secondary) {
				imageUrl += Vgr.constants.content.vertownBlackberryLogoLink;
			} else {
				imageUrl += Vgr.constants.content.vertownBlueLogoLink;
			}
			$scope.logo = imageUrl;
		};
	},
	templateUrl: "views/components/images/vgrLogo/vgrLogo_template.html",
	bindings: {
		white: "<?",
		secondary: "<?",
		customClass: "@?"
	}
});
